import React, { useEffect, useState } from "react";
import minterAbi from "../utils/minterAbi.json";
import bootstrapMerkleProof from "./merkle"
import { ethers } from "ethers";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";

const MintPopup = ({ mintPopup, setMintPopup }) => {
    const [numberOfNFTs, setMintsNumber] = useState(false);
    const [mintMessage, setMintMessage] = useState({
        color: "green",
        text: "",
    });
    const [remainingsupply, setRemaingSupply] = useState(null);
    const [mintfee, setMintFee] = useState(null);
    const [payableAmount, setPayableAmout] = useState(null);
    const [myProof, setMyProof] = useState(null)
    const { library, account } = useWeb3React();
    const theme = localStorage.getItem("theme");

    const env = "prod";
    const config = {
        dev: {
            minterContract: '0x566AEc7F02CeaF6Ba24BCDd7dC3A7aE164a29C9E',
            mainContract: '0x8d6713b1421605b7240dEb7803BcCCC677e69a5b',
        },
        prod: {
            minterContract1: '0xce5B2Aa04623d5F46d346d91aA0A587556A09F14',
            minterContract: '0x35CbF6d47C5eFCFdd52b4696E62811Ab69aED22e',
            mainContract: '0x7c98eF950DcB9D1cC035636aab4291A81711237A',
        },
    }

    const handleChange = async (val) => {
        const intVal = parseInt(val);

        if (intVal < 0) {
            setMintsNumber(1);
        } else {
            setMintsNumber(val);
        }

        const web3 = new Web3(library);
        const price = web3.utils.toWei((mintfee * intVal).toString(), "ether");
        setPayableAmout(price / Math.pow(10, 18));
    };

    const initiateMint = async () => {
        if (account.length < 42) {
            return;
        }
        if (numberOfNFTs <= 0 || numberOfNFTs > parseInt(remainingsupply)) {
            setMintMessage({ color: "red", text: "Invalid mint quantity!" });
            return;
        }

        if (remainingsupply == 0) {
            setMintMessage({
                color: "red",
                text: "Not enough supply remaining in this round!",
            });
            return;
        }

        const web3 = new Web3(library);
        const contract = new web3.eth.Contract(
            minterAbi,
            config[env].minterContract
        );

        if (myProof && myProof.length > 0) {
            await contract.methods
                .mintWhitelist(numberOfNFTs, myProof)
                .send({
                    from: account,
                    value: new BigNumber(payableAmount)
                        .multipliedBy(10 ** 18)
                        .toFixed(),
                })
                .then((result) => {
                    setMintMessage({
                        color: "green",
                        text: "Mint successful! Congratulations",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setMintMessage({
                        color: "red",
                        text: "An error occured on-chain, please check browser console.",
                    });
                });
        } else {
            await contract.methods
                .mint(numberOfNFTs)
                .send({
                    from: account,
                    value: new BigNumber(payableAmount)
                        .multipliedBy(10 ** 18)
                        .toFixed(),
                })
                .then((result) => {
                    setMintMessage({
                        color: "green",
                        text: "Mint successful! Congratulations",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setMintMessage({
                        color: "red",
                        text: "An error occured on-chain, please check browser console.",
                    });
                });
        }
    };

    useEffect(() => {
        const getData = async () => {
            const web3 = new Web3(library);

            const contract = new web3.eth.Contract(
                minterAbi,
                config[env].minterContract
            );

            const baseFee = myProof && myProof.length > 0 ? await contract.methods.whitelistMintFee().call() : await contract.methods.publicMintFee().call()
            const fee = baseFee / Math.pow(10, 18);

            setMintFee(fee);
            const supply = await contract.methods.remainingSupply().call();
            setRemaingSupply(supply);
        };

        getData();
    }, [myProof]);

    useEffect(() => {
        if (!account) return

        const { merkleTree } = bootstrapMerkleProof()
        const proof = merkleTree.getHexProof(ethers.utils.solidityKeccak256(
            ["address"],
            [account]
        ))

        setMyProof(proof)
    }, [account])

    return (
        <>
            <div
                className={`${mintPopup ? "mint_popup" : "animate"} ${theme === "light" ? "lightpopup" : "darkpopup"
                    } `}
            >
                <button
                    className="close_btn"
                    onClick={() => setMintPopup(false)}
                >
                    <img src={"./images/cross.png"} alt="cross icon" />
                </button>
                <input
                    onChange={(e) => handleChange(e.target.value)}
                    className={`${theme === "light" ? "light_input" : "dark_input"
                        }`}
                    placeholder="Amount"
                    type="number"
                    value={numberOfNFTs}
                />

                {remainingsupply && (
                    <span
                        className={`${theme === "light" ? "nft_light" : "nft_dark"
                            }`}
                    >
                        {remainingsupply} NFTs available to be minted in this
                        phase
                    </span>
                )}
                {payableAmount && (
                    <span
                        className={`${theme === "light" ? "nft_light" : "nft_dark"
                            }`}
                    >
                        You will be paying {payableAmount} ETH
                    </span>
                )}

                {remainingsupply == 0 && (
                    <span className="nft_down">Phase not started yet!</span>
                )}
                <button
                    onClick={initiateMint}
                    className="mint_btn"
                    disabled={remainingsupply == 0}
                >
                    <span>Mint</span> <img src="./images/button.png" />
                </button>

                <span className="nft_down" style={{ color: mintMessage.color }}>
                    {mintMessage.text}
                </span>
            </div>
        </>
    );
};

export default MintPopup;
