import React from "react";

const Whitelist = () => {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: "<iframe src='https://misfitswl.kneeverse.io/index.html' style='width:100vw;height:100vh' />",
            }}
        />
    );
};

export default Whitelist;
