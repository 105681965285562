
import Header from "../common/header";
import React from "react";

export default function Bushiness_plan() {
    const [theme, settheme] = React.useState(localStorage.getItem("theme"))
    return (
        <>
            <div class={"container-fluid" + ' ' + (theme === "light" ? "bushinessbglight" : "bushinessbg")}>
                <div class="container">
                    <Header active={"4"} />
                    <div class="business">
                        <div class="middel mobile">
                            <h2>Business Plan</h2>
                            <p>Every good vision requires a plan.</p>
                        </div>
                        <div class="left">
                            <ul>
                                <li><span>Key Partners </span><img src="./images/key partners/businesstabactive.png" alt="" />
                                </li>
                                <li><span>Opensea </span><img src="./images/key partners/1.png" alt="" /></li>
                                <li><span>Pinata</span><img src="./images/key partners/2.png" alt="" /></li>
                                <li><span>Github</span><img src="./images/key partners/3.png" alt="" /></li>
                                <li><span>Vercel</span><img src="./images/key partners/4.png" alt="" /></li>
                                <li><span>Revenue Streams</span><img src="./images/key partners/5.png" alt="" /></li>
                                <li><span>Token Sales</span><img src="./images/key partners/6.png" alt="" /></li>
                                <li><span>Royalties</span><img src="./images/key partners/7.png" alt="" /></li>
                                <li><span>Comic Sales</span><img src="./images/key partners/8.png" alt="" /></li>
                                <li><span>Merchandise</span><img src="./images/key partners/9.png" alt="" /></li>
                            </ul>
                            <ul>
                                <li><span>Key Activities </span><img src="./images/key partners/businesstabactive.png" alt="" />
                                </li>
                                <li><span>Concept Developer </span><img src="./images/key partners/1.png" alt="" /></li>
                                <li><span>Community Developer</span><img src="./images/key partners/2.png" alt="" /></li>
                                <li><span>Tokenomic Developer</span><img src="./images/key partners/3.png" alt="" /></li>
                                <li><span>Lore Developer</span><img src="./images/key partners/4.png" alt="" /></li>
                                <li><span>Cost Structure</span><img src="./images/key partners/5.png" alt="" /></li>
                                <li><span>Office</span><img src="./images/key partners/6.png" alt="" /></li>
                                <li><span>Employees</span><img src="./images/key partners/7.png" alt="" /></li>
                                <li><span>Contract
                                    Registration</span><img src="./images/key partners/8.png" alt="" /></li>
                                <li><span>Marketing</span><img src="./images/key partners/9.png" alt="" /></li>
                            </ul>
                        </div>
                        <div class="middel dasktop">
                            <h2>Business Plan</h2>
                            <p>Every good vision requires a plan.</p>
                            <img src="./images/key partners/monkey.png" alt="" />
                        </div>
                        <div class="right">
                            <ul>
                                <li><span>Value Proposition </span><img src="./images/key partners/businesstabactive.png" alt="" />
                                </li>
                                <li><span>Scarcity </span><img src="./images/key partners/1.png" alt="" /></li>
                                <li><span>Comic Book</span><img src="./images/key partners/2.png" alt="" /></li>
                                <li><span>Community DAO</span><img src="./images/key partners/3.png" alt="" /></li>
                                <li><span>Character IP</span><img src="./images/key partners/4.png" alt="" /></li>
                                <li><span>All Channels</span><img src="./images/key partners/5.png" alt="" /></li>
                                <li><span>Opensea</span><img src="./images/key partners/6.png" alt="" /></li>
                                <li><span>Website</span><img src="./images/key partners/7.png" alt="" /></li>
                                <li><span>Twitter/
                                    Youtube</span><img src="./images/key partners/8.png" alt="" /></li>
                                <li><span>Discord</span><img src="./images/key partners/9.png" alt="" /></li>
                            </ul>
                            <ul>
                                <li><span>Customer
                                    Relationship</span><img src="./images/key partners/businesstabactive.png" alt="" /></li>
                                <li><span>Long-Term </span><img src="./images/key partners/1.png" alt="" /></li>
                                <li><span>Co-Creation</span><img src="./images/key partners/2.png" alt="" /></li>
                                <li><span>Communities</span><img src="./images/key partners/3.png" alt="" /></li>
                                <li><span>Dedicated</span><img src="./images/key partners/4.png" alt="" /></li>
                                <li><span>Key Resources</span><img src="./images/key partners/5.png" alt="" /></li>
                                <li><span>Branding</span><img src="./images/key partners/6.png" alt="" /></li>
                                <li><span>Platform</span><img src="./images/key partners/7.png" alt="" /></li>
                                <li><span>Knowledge</span><img src="./images/key partners/8.png" alt="" /></li>
                                <li><span>IP</span><img src="./images/key partners/9.png" alt="" /></li>
                            </ul>
                        </div>
                        <div class="middel mobile">
                            <img src="./images/key partners/monkey.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}