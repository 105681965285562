import React from "react";
import Accordion from "./Accordion";
import Header from "../../common/header";


const questionsAnswers = [
    {
        question: "What is a Manga?",
        answer:
            "Manga’s are comics or graphic novels that originated in Japan. This culture has now become a world-wide phenomenon. Traditionally Manga is read from right to left, to honor the culture from where the Misfits story is inspired it will be presented in this format.",
    },
    {
        question: "Mint Date?",
        answer:
            "The date of the Mint is still to be decided based on several factors, we are aiming for the end of May based on current expectations.",
    },
    {
        question: "Mint Price?",
        answer: `The Mint Price is currently set at 0.05ETH + Gas per Misfit. This price is determinant on the Ethereum price and is subject to change, we have a set budget in fiat that we need to reach in order to deliver on the promises made in the Vision Map.`,
    },
    {
        question: "How many Misfits in the Collection?",
        answer: `There are a total of 4,000x Misfits within the collection. This consists of 200x Genesis Misfits and 3,800x Main collection Misfits. `,
    },
    {
        question: "Difference between Genesis & Main collection?",
        answer: `The Genesis Misfits are the OG tokens for our early supporters, these entitle holders to exclusive giveaways, exclusive merch, exclusive traits and so much more. The main collection will also have a whole host of benefits including but not limited to access to official merchandise, earning of $CRANK, contribution to the Misfits story and so much more!`,
    },
    {
        question: "What is $CRANK?",
        answer: `$CRANK is our Native Token and stands for Citizen Rank. It will be earned by holders and may be used to purchase merchandise, upgrade your Misfits, for comic sales redistribution to holders and so much more!`,
    },
    {
        question: "What is S.E.L.K?",
        answer: `This is the organisation that maintains order through the five districts of Arcadia. It stands for Sovereign Enryo Liberation Kokkai. Enryo is the Japanese concept of restraint and Kokkai is for Congress.`,
    },
];

const Faq = () => {
    const [theme, settheme] = React.useState(localStorage.getItem("theme"))
    return (
        <div class={"container-fluid" + ' ' + (theme == "light" ? "lightbgfaq" : "bgfaq")}>
            <div class="container">
                <Header active={"6"} />
                <div class="faq">

                    <div class="right">
                        <h2>FAQ</h2>
                        <Accordion questionsAnswers={questionsAnswers} />
                    </div>
                    <div class="left">
                        <img src="./images/faqgirl.png" alt="girl" srcset="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;