import Header from "../common/header"
import React from 'react'

export default function Home() {
    const [theme, settheme] = React.useState(localStorage.getItem("theme"))
    return (
        <>
            <div className="container-fluid">
                <div className="banner">
                    <img className="dasktophow" src={theme == "light" ? "./images/banner-new.gif" : "./images/dark_banner.jpg"} alt="banner" />
                    <img className="mobileshow" src={theme == "light" ? "./images/litemobilebg.svg" : "./images/mobilebg.svg"} alt="banner" />
                    <div className="container">
                        <Header active={"1"} />
                        <div className="banner-text">
                            <p>
                                <img src="./images/vactorback.png" alt="" />
                                <span>
                                    These misfits are about to be thrown unwillingly into a wild adventure to discover the truth of corruption that lies over Arcadia. Become the co-author of an epic Manga full of incredible adventures!
                                </span>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}