import Header from "../common/header";
import React from "react";

export default function Vision_map() {
    const [theme, settheme] = React.useState(localStorage.getItem("theme"))
    return (
        <>
            <div class={"container-fluid" + ' ' + (theme == "light" ? "light" : "darkbg")}>
                <div class="container">
                    <Header active={"2"} />
                    <div class="vision_map">
                        <h2>Vision MAP</h2>
                        <p className={theme == "light" ? "light" : "dark"}>Our vision is to be the largest Manga series co-created with the NFT Community.</p>
                        <ul class="first">
                            <li>
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <img src="./images/vision1.png" alt="" />
                                    </div>
                                    <div class="flip-card-back">
                                        <h1>	Expand Marketing</h1>
                                        <p>Increased investment into marketing that creates consistency across all Misfits touchpoints (Website, Twitter, YouTube, Instagram etc). Influencer marketing budget also increased to improve reach and brand awareness. </p>

                                    </div>
                                </div>
                            </li>
                            <li>

                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <img src="./images/vision2.png" alt="" />
                                    </div>
                                    <div class="flip-card-back">
                                        <h1>Genesis Launch</h1>
                                        <p>The Genesis tokens were launched for early supporters of the Misfits Vision. The Genesis tokens provide holders with several benefits…</p>
                                        <p>Exclusive traits that will not be found in the main collection</p>
                                        <p>Earn extra $CRANK as a Genesis Minter</p>
                                        <p>Exclusive Genesis Merch</p>
                                        <p>Discounted Mint price for Season 2 - The Villains</p>
                                        <p>Physical copy of Vol. 1 of the Manga</p>
                                        <p>Animated NFT Airdrops</p>
                                        <p>Exclusive Giveaways</p>

                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="second">
                            <li>

                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <img src="./images/vision4.png" alt="" />
                                    </div>
                                    <div class="flip-card-back">
                                        <h1>50% Sold</h1>
                                        <p>MANGA PRODUCTION BEGINS! We have already begun working with a comic studio to produce the first chapter of the Misfits Cover art as well as the first 3x pages! We also have a professional writer that has worked with several big studios. </p>
                                        <p>Genesis Merchandise and Exclusive Genesis Giveaway.</p>
                                    </div>
                                </div>
                            </li>
                            <li>

                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <img src="./images/vision3.png" alt="" />
                                    </div>
                                    <div class="flip-card-back">
                                        <h1>New Traits </h1>
                                        <p>There will be over 200x new traits across the 5x characters as well as 5x new 1 of 1’s and 5x super rares added to the main collection drop. </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="third">
                            <li>

                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <img src="./images/vision6.png" alt="" />
                                    </div>
                                    <div class="flip-card-back">
                                        <h1>$CRANK Development</h1>
                                        <p>Our native token $CRANK will begin development. This token can be used to buy Official Misfits Merchandise, upgrade your Misfit (ie custom clothing), and Manga proceeds redistribution. </p>

                                    </div>
                                </div>
                            </li>
                            <li>

                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <img src="./images/vision5.png" alt="" />
                                    </div>
                                    <div class="flip-card-back">
                                        <h1>100% Sold</h1>
                                        <p>Genesis Animated NFT airdrops. Season 2 production begins ‘The Villains’, holders Art competition for the Villains is launched – Winner will be compensated for their design.</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="forth">
                            <li>
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <img src="./images/vision7.png" alt="" />
                                    </div>
                                    <div class="flip-card-back">
                                        <h1>Manga Launch</h1>
                                        <p>The first 5 Chapters (Vol. 1) of the Misfits Manga are launched. Community DAO established to help direct the story for Vol. 2 and beyond. </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}