import { toBeChecked } from "@testing-library/jest-dom/dist/matchers";
import React from "react";
import MintPopup from "./MintPopup";
import { Link } from "react-router-dom";
import { injected } from "../connectors";
import { useWeb3React } from "@web3-react/core";

export default function Header(props) {
    const [theme, settheme] = React.useState(localStorage.getItem("theme"));

    const [menu, setmenu] = React.useState("");
    const [mintPopup, setMintPopup] = React.useState(false);
    const { active, account, library, connector, activate, deactivate } =
        useWeb3React();

    const theme_change = () => {
        let themeset = theme == "light" ? "dark" : "light";
        settheme(theme);
        localStorage.setItem("theme", themeset);
        window.location.reload();
    };

    const openPopup = async () => {
        await activate(injected);
        setMintPopup(true);
    };

    return (
        <>
            <header>
                <div className={theme == "light" ? "menu light" : "menu"}>
                    <div className="logo">
                        <Link to="/">
                            <img src="./images/logo.png" alt="logo" />
                        </Link>
                        <div className="dasktop menuicon">
                            <button onClick={(e) => setmenu("active")}>
                                <img
                                    src={
                                        theme == "light"
                                            ? "./images/menudark.png"
                                            : "./images/menu.png"
                                    }
                                />
                            </button>
                        </div>
                    </div>

                    <div className={"mobile" + " " + menu}>
                        <div className="dasktop menuicon cancel">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    onChange={(e) => theme_change()}
                                    checked={theme == "light" ? true : false}
                                />
                                <span className="slider round"></span>
                            </label>
                            <button onClick={(e) => setmenu("")}>
                                <img src="./images/cross.png" />
                            </button>
                        </div>
                        <ul className="daskmenu">
                            <li>
                                <Link
                                    className={
                                        props.active == "6" ? "active" : ""
                                    }
                                    to="/faq"
                                >
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={
                                        props.active == "5" ? "active" : ""
                                    }
                                    to="/team"
                                >
                                    Team
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={
                                        props.active == "4" ? "active" : ""
                                    }
                                    to="/business-plan"
                                >
                                    Business Plan
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={
                                        props.active == "3" ? "active" : ""
                                    }
                                    to="/lore"
                                >
                                    Lore
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={
                                        props.active == "2" ? "active" : ""
                                    }
                                    to="/vision-map"
                                >
                                    Vision map
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/"
                                    className={
                                        props.active == "1" ? "active" : ""
                                    }
                                >
                                    Arcadia
                                </Link>
                            </li>
                        </ul>
                        <ul className="mobilemenu">
                            <li>
                                <Link
                                    to="/"
                                    className={
                                        props.active == "1" ? "active" : ""
                                    }
                                >
                                    Arcadia
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={
                                        props.active == "2" ? "active" : ""
                                    }
                                    to="/vision-map"
                                >
                                    Vision map
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={
                                        props.active == "3" ? "active" : ""
                                    }
                                    to="/lore"
                                >
                                    Lore
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={
                                        props.active == "4" ? "active" : ""
                                    }
                                    to="/business-plan"
                                >
                                    Business Plan
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={
                                        props.active == "5" ? "active" : ""
                                    }
                                    to="/team"
                                >
                                    Team
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={
                                        props.active == "6" ? "active" : ""
                                    }
                                    to="/faq"
                                >
                                    FAQ
                                </Link>
                            </li>
                        </ul>
                        <div className="mint">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    onChange={(e) => theme_change()}
                                    checked={theme == "light" ? true : false}
                                />
                                <span className="slider round"></span>
                            </label>
                            <button onClick={openPopup}>
                                <span>Mint</span>{" "}
                                <img src="./images/button.png" />
                            </button>
                            <ul>
                                <li>
                                    <a
                                        href="https://twitter.com/themisfitsnft"
                                        target="_blank"
                                    >
                                        <img
                                            src={
                                                theme == "light"
                                                    ? "./images/twiter_red.png"
                                                    : "./images/twiter.png"
                                            }
                                            alt="twiter icon"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://discord.gg/h2nVU2psXp"
                                        target="_blank"
                                    >
                                        <img
                                            src={
                                                theme == "light"
                                                    ? "./images/ficon_red.png"
                                                    : "./images/ficon.png"
                                            }
                                            alt="discord icon"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://opensea.io/collection/the-misfits-nft"
                                        target="_blank"
                                    >
                                        <img
                                            src={
                                                theme == "light"
                                                    ? "./images/bot_red.png"
                                                    : "./images/boat.png"
                                            }
                                            alt="opensea icon"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.youtube.com/channel/UCCKB8wenNqu0uGklYIVONEg"
                                        target="_blank"
                                    >
                                        <img
                                            src={
                                                theme == "light"
                                                    ? "./images/youtubered.png"
                                                    : "./images/youtube.png"
                                            }
                                            alt="youtube icon"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {mintPopup && (
                <MintPopup
                    account={account}
                    mintPopup={mintPopup}
                    setMintPopup={setMintPopup}
                />
            )}
        </>
    );
}
