import React from 'react'
import Header from "../common/header";

export default function Team() {
    const [theme, settheme] = React.useState("dark")
    const [Marketing, setMarketing] = React.useState(false)
    const [Creative, setCreative] = React.useState(false)
    const [Developer, setDeveloper] = React.useState(false)
    return (
        <>
            <div class="container-fluid">
                <div className='container headerteam' >
                    <Header active={"5"} />
                </div>
                <div class="team">
                    <div class="left">
                        <span></span>
                        <div class="text">
                            <div class="shoe_section">
                                <span>Operations & Marketing Director</span>
                                <h2>Tom Frazer</h2>
                            </div>
                            <p>From a young age I have been obsessed with video games and collecting – it all started in primary school when my friend gave me a Yu-Gi-Oh card.</p>
                            <div className='link'>
                                <a href="#" onClick={e => setMarketing(Marketing ? false : true)}>Read More <img src="./images/right.png" alt="right icon" />
                                </a>
                                <ul>
                                    <li>
                                        <a href="https://twitter.com/TomFrazerNFT" target="_blank"><img src={theme == "light" ? "./images/twiter_red.png" : "./images/twiter.png"} alt="twiter icon" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="middel">
                        <span></span>
                        <div class="text">
                            <div class="shoe_section">
                                <span>Creative Director </span>
                                <h2>Alex Lalos</h2>
                            </div>
                            <p>I’ve loved anime since I can remember. Having grown up renting Akira and Ghost in the Shell from the video store never quite understanding what I was watching.</p>
                            <div className='link'>
                                <a href="#" onClick={e => setCreative(Creative ? false : true)}>Read More <img src="./images/right.png" alt="right icon" />
                                </a>
                                <ul>
                                    <li>
                                        <a href="https://twitter.com/laleybloX" target="_blank"><img src={theme == "light" ? "./images/twiter_red.png" : "./images/twiter.png"} alt="twiter icon" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <span></span>
                        <div class="text">
                            <div class="shoe_section">
                                <span>Lead Developer</span>
                                <h2>Ghulam Haider</h2>
                            </div>
                            <p>I am a Blockchain solutions developer with expertise in network architecture design. I hold both a bachelor and master’s degree in computer science. {Developer ? "In addition, I love staying up to date with technological trends and ensure that I am always in a position where I can complete tasks as per the latest innovations within Web3. For instance, with the Misfits, we were able to achieve some of the lowest gas fees seen within the NFT space during the Genesis launch. I will continue to leverage my years of experience and knowledge to provide holders with the best experience possible throughout their journey with the Misfits project." : ""}</p>
                            <div className='link'>
                                <a href="#" onClick={e => setDeveloper(Developer ? false : true)}>Read More <img src="./images/right.png" alt="right icon" />
                                </a>
                                <ul>
                                    <li>
                                        <a href="https://twitter.com/ihaidersattar" ><img src={theme == "light" ? "./images/twiter_red.png" : "./images/twiter.png"} alt="twiter icon" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={Marketing ? "popup active" : "popup"}>
                    <button onClick={e => setMarketing(false)}><img src="./images/cross.png" /></button>
                    <p>From a young age I have been obsessed with video games and collecting – it all started in primary school when my friend gave me a Yu-Gi-Oh card. "This then led to a downward degen spiral collecting hundreds of Yu-Gi-Oh and Pokémon cards with friends. I am completely obsessed with blockchain technology and the way in which it is reshaping our society.  I’ve worked in corporate finance for 5 years, split between London and Singapore. While in London I worked for one of the largest marketing and communication groups in the world within their treasury department. In understanding how the business operated I developed a love for marketing and decided to obtain my master’s degree in Management with a specialisation in Digital Marketing. I am certain that both my treasury experience and marketing knowledge will allow us to build this project the right way, putting your money in the right places to ensure that we can grow the Misfits brand into something that will take the NFT and Manga world by storm!"</p>
                </div>
                <div className={Creative ? "popup active" : "popup"}>
                    <button onClick={e => setCreative(false)}><img src="./images/cross.png" /></button>
                    <p>I’ve loved anime since I can remember. Having grown up renting Akira and Ghost in the Shell from the video store never quite understanding what I was watching.From there I immersed myself into anime culture - when in the mid-90s the anime was hitting the west from Evangelion, GTO all the way to Yu Yu Hakusho and Card Captor Sakura.<br /><br /> I was a full-fledged OTAKU, before I knew what the word even meant. <br />
                        <br /> I became heavily obsessed with crypto back in 2016 and have always advocated for blockchain and decentralisation. When I finally came around to the world of NFT, that passionate collector roared within me! I knew that one day I would create a project centred around my love for the art which had such a big impact on my life growing up.
                        <br />
                        <br /> Thus, the Misfits was born!</p>
                </div>
                <div className={Developer ? "popup active" : "popup"}>
                    <button onClick={e => setDeveloper(false)}><img src="./images/cross.png" /></button>
                    <p>I am a Blockchain solutions developer with expertise in network architecture design. I hold both a bachelor and master’s degree in computer science. In addition, I love staying up to date with technological trends and ensure that I am always in a position where I can complete tasks as per the latest innovations within Web3. For instance, with the Misfits, we were able to achieve some of the lowest gas fees seen within the NFT space during the Genesis launch. I will continue to leverage my years of experience and knowledge to provide holders with the best experience possible throughout their journey with the Misfits project.</p>
                </div>
            </div>
        </>
    )
}