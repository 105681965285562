const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");
const ethers = require("ethers")

const bootstrapMerkleTree = () => {
  const wallets = require("./whitelist.json")

  const leaves = []
  for (const wallet of wallets) {
    leaves.push(ethers.utils.solidityKeccak256(
      ["address"],
      [wallet]
    ))
  }

  const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true })
  const rootHash = "0x" + merkleTree.getRoot().toString("hex")
  return { merkleTree, leaves, rootHash }
}

export default bootstrapMerkleTree