import Header from "../common/header";
import React from "react";

export default function Lore() {
    const [theme, settheme] = React.useState(localStorage.getItem("theme"))
    const [active, setactive] = React.useState({})
    return (
        <>
            <div class={"container-fluid" + ' ' + (theme === "light" ? "drkbglore" : "bglore")}>
                <div class="container">
                    <Header active={"3"} />
                    <div class="lore">
                        <div class="left">
                            <ul>
                                <li onClick={e => setactive({ "path": "./images/lora/Dex LORE.png" })}><img src={active === "dex" ? "./images/activedex.png" : "./images/dex.png"} /><span>Bio</span></li>
                                <li onClick={e => setactive({ "path": "./images/lora/BAZ LORE.png" })}><img src={active === "buzz" ? "./images/activebuzz.png" : "./images/buzz.png"} /><span>Bio</span></li>
                            </ul>
                            <ul>
                                <li onClick={e => setactive({ "path": "./images/lora/Trixie LORE.png" })}><img src={active === "alex" ? "./images/acrivealex.png" : "./images/alex.png"} /><span>Bio</span></li>
                                <li onClick={e => setactive({ "path": "./images/lora/Izzy LORE.png" })}><img src={active === "izzy" ? "./images/activeizzy.png" : "./images/izzy.png"} /><span>Bio</span></li>
                            </ul>
                            <ul>
                                <li onClick={e => setactive({ "path": "./images/lora/Trip LORE.png" })}><img src={active === "trip" ? "./images/activetrip.png" : "./images/trip.png"} /><span>Bio</span></li>
                            </ul>
                        </div>

                        <div class="right">
                            <h2>LORE</h2>
                            <div className="tab1"  >
                                <p>In the year 2063, dreams no longer exist in the city of Arcadia. Because those at the top have everything their hearts desire and no reason to dream. Rich with C.R.A.N.K., the currency of Arcadia that allows citizens to travel through the city, those at the top are free to move back and forth between the higher districts. But those at the bottom…well, what’s the point in dreaming when you know you will never have more. Or become more. But that doesn’t stop a few foolish souls from holding out hope.</p>
                                <p>Deep in the lower districts where crime is rampant, an organization known as S.E.L.K. has developed a unique solution to keep the peace. Citizens can earn C.R.A.N.K. by bringing in or even assassinating criminals. The only ones foolish enough to accept these jobs are known as MISFITS, the toughest and craziest citizens in Arcadia. One of these MISFITS, a loner named Trip, has just been offered the job of a lifetime. He’s been offered a chance to escape the slums and change his fate. And the only thing standing in his way are two orphan girls, a psychotic ape, and a cyborg bear, all of whom are also looking to change their fates.
                                </p>
                                <p>Dreams no longer exist in the city of Arcadia. But maybe they will again one day soon.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className={active.path ? "popup active" : "popup"}>
                        <button onClick={e => setactive({})}><img src="./images/cross.png" /></button>
                        <img src={active.path} />
                    </div>
                </div>
            </div>
        </>
    )
}