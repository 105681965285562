import React, { useEffect } from "react";
import {
    Route,
    Redirect,
    Switch,
    BrowserRouter as Router,
} from "react-router-dom";
import Home from "./pages/index";
import Faq from "./pages/faq/faq";
import Team from "./pages/team";
import Lore from "./pages/lore";
import Vision_map from "./pages/vision-map";
import Bushiness_plan from "./pages/business-plan";
import Whitelist from "./pages/wl";

import ReactGA from "react-ga";
const TRACKING_ID = "UA-230157894-1";
ReactGA.initialize(TRACKING_ID)

function App() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/vision-map" component={Vision_map} />
                    <Route exact path="/lore" component={Lore} />
                    <Route exact path="/faq" component={Faq} />
                    <Route exact path="/team" component={Team} />
                    <Route
                        exact
                        path="/business-plan"
                        component={Bushiness_plan}
                    />
                    <Route exact path="/wl" component={Whitelist} />
                    <Route component={Home} />
                </Switch>
            </Router>
        </>
    );
}

export default App;
